.about{

  background-image: linear-gradient(174.2deg, rgba(65, 63, 63, 0.02) 50.94%, rgba(3, 20, 11, 0.4) 88.97%),url("../../assets/Texture.png");
  background-size: contain;
  background-repeat: repeat;
  //background-position:center;
  background-attachment: fixed;
  min-height: 100vh;
  padding: 2rem 0;
}

.opq-card{
  background-color: rgba(0,0,0,.8);
  padding: 4rem;
}

.mediaCard{
  background-color: rgba(0,0,0,.8);
  padding: 2rem;
  position: relative;
  border-left: 4px dashed rgba(0,0,0,.8);
  border-bottom: 4px dashed rgba(0,0,0,.8);
  transition: all .35s cubic-bezier(0.23,.13,.65,.96);
  &:not(:last-child){
    margin-bottom: 1.5rem;
  }
  &:after{
    content: "";
    padding: 2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30%;
    width: 15%;
    //background-color: #37A050;
    background-position: center;
  }
  &:hover{
    border-left: 4px dashed var(--primary);
    border-bottom: 4px dashed var(--primary);
    transform: translateY(-.4rem);
  }
}