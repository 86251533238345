
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600&family=Raleway:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');*,*:after,*:before{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Poppins','Open Sans', sans-serif;;
}

:root{
  --primary: #11DF70;
  --prim: #79E76C;
  --primary-dark: #2ca05e;
  --primary-light: #AEF1A6;
  --primary-lime: #94A433;
  --darkk:#000000;
  --dark:#262626;
  --dark-alt:#484848;
  --dark-alt2:#282828;
  --dark-light:#C4C4C4;
  --white: #ffffff;
  --white-alt: #EAEAEA;
  --gold: #C49437;
  --yorfanGreen:#366B3A;

  --green-gradient:linear-gradient(191.31deg, #79E76C -16.22%, #27821C 91.67%);



}


html{
  font-size: 50%;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
 scroll-behavior: smooth;
  @include respond(tabLand){
    font-size: 50%;
  }
  @include respond(tabPort){
    font-size: 56.25%;
  }
  @include respond(phone){
    font-size: 50%;
  }


  @include respond(bigScreen){
    font-size: 75%;
  }
}


body{
}