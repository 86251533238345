@import "../base/mixin";
.navigation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: rgba(0,0,0,.5);
  padding: 2rem;
  backdrop-filter: blur(25px);
  @include respond(tabPort){
    width: 95%;


  }
  &-list{
    display: flex;
    justify-content: space-between;
    background-color: rgba(0,0,0,.8);
    padding: 1.6rem;
    list-style: none;
    position: relative;
    flex: .4;
    @include respond(phone){
      display: inline-flex;
      flex-wrap: wrap;
      justify-content:flex-end;
    }
    @include respond(tabPort){
      display: none;
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      flex-direction: column;
      background-color: rgba(0,0,0,.95);
      z-index: 100;
      align-items: center;
      justify-content: center;
    }
    &:after{
      content: "";
      width: 75%;
      height: .6rem;
      background-color: var(--primary);
      position: absolute;
      bottom: -4rem;
      right: 0;
    }
    &-item{
      font-size: 1.8rem;

      &:not(:last-child){
        margin-right: 1rem;
      }
      a{
        text-decoration: none;
        color: #f7f7f7;
        &:hover{
          color: var(--primary);
        }
      }

    }
  }
}

.mob_menu{
  @include respond(tabPort){
    position: fixed;
    top: 4rem;
    right: 4rem;
  }
}
.mob_logo{
  display: none;
  @include respond(tabPort){

    display: block;
    @supports (background-attachment: fixed) {

      display: none;
    }
  }
}