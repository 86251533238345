.relatedArticle{
   padding: 1.5rem;
  background-color: var(--dark-alt2);
  width: 45%;
  @include respond(tabPort){
    width: 100%;
  }
  &:not(:last-child){
    margin-bottom:1.5rem ;
  }
}
