.footer-reach{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  &Top{
    position: absolute;
    top: -4.5rem;
    right: -4.2rem;
  }
  &Bottom{
    position: absolute;
    bottom: -5rem;
    left: -4.5rem;
  }

  form{
    position: relative;
    width: 90%;
    padding: 4rem 2.5rem;
    background-color: var(--darkk);
    .form-input-box{
      background-color: var(--dark-alt);
      .form-input{
        padding: 1.2rem;
        background-color: inherit;
        border: none;
        width: 100%;
        margin-bottom: 1.5rem;
        color: white;
      }
      .form-text{
        padding: 1.2rem;
        background-color: var(--dark-alt);
        border: none;
        height: 15rem;
        width: 100%;
        resize: none;
        color: white;
      }
    }
  }
}


.faq-search{
  display: flex;

  background-color: var(--dark-alt);
  align-items: center;
  padding: .5rem;
  width: 45%;
  @include respond(tabPort){
    width: 100%;
  }
  input{
    border: none;
    background-color: inherit;
    padding: 1.5rem;
    flex: 1;
    font-size: 1.8rem;
    color: #f7f7f7;
    &:focus{
      outline: none;
    }
    &::-webkit-input-placeholder{
      color: #f7f7f7;
    }
  }
}


.form-file{
  padding: 2rem;
  //border-radius: 1.5rem;
  background-color: #e5e5e5;
  border: 2px dashed #35495e;

  .file-input{
    border: none;
  }
}

#file_type_input{
  display: none;
}


.form-select{
  padding:1.2rem 1rem;
  border: none;
  background-color: inherit;
  width: 100%;
  color: var(--dark);
  &:focus{
    outline: none;
  }
}
.form-textArea{
  padding:1.2rem 1rem;
  border: none;
  background-color: inherit;
  width: 100%;
  height: 5rem;
  resize: none;
  color: var(--dark);
  &:focus{
    outline: none;
  }
}

.form-input{
  padding:0.2rem 1.2rem;
  border: none;
  background-color: inherit;
  width: 100%;

  color: var(--text-inverse);
  &:focus{
    outline: none;
  }
}

.form-input-box{
  padding: .8rem .5rem;
  background-color: #e5e5e5;
  //border-radius: 1rem;
  margin: 1.6rem 0;}