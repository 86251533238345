.hero{
  min-height: 180vh;
  padding: 1.5rem;
  background-image: linear-gradient(174.2deg, rgba(65, 63, 63, 0.02) 50.94%, rgba(3, 20, 11, 0.9) 88.97%),url("../../assets/hero.png");
  background-size: cover;
  background-position:50%;
  background-color: #000000;
  position: relative;
  background-attachment: scroll;
  @supports (background-attachment: fixed) {
    background-attachment: fixed;
  }

  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include respond(tabPort){
    //background-position: bottom right;
    background-image: linear-gradient(164.2deg, rgba(65, 63, 63, 0.02) 50.94%, rgba(2, 38, 19, .8) 88.97%),url("../../assets/hero.png");
   background-position: 30%;
    min-height: 180vh;
  }
  @include respond(phone){
    //background-position: bottom right;
    background-image: linear-gradient(164.2deg, rgba(65, 63, 63, 0.02) 50.94%, rgba(2, 38, 19, .8) 88.97%),url("../../assets/hero-mob.png");
    min-height: 180vh;
    @supports (background-attachment: fixed) {
      background-attachment: fixed;

      min-height: 180vh;
    }
  }
  .top-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem ;
    width: 90%;
    margin: 0 auto;
  }
  .hero-center{
    display: flex;
    justify-content: center;
    .lgText{
      font-size: 7.2rem ;
      color: white;
      position: relative;
      .leaf{
        position: absolute;
        top: -1.4rem;
        margin-left: -.2rem;
      @include respond(phone){
        &Icon{

            height: 4.8rem;
        }
      }
      }
    }
  }
  .bottom-nav{

    padding: 2rem 2rem;
    width: 90%;
    margin: 0 auto;
    @include respond(tabPort){
      width: 98%;
    }
    &-top{
      display: flex;
      justify-content: space-between;
      align-items: center;

    }
    &-about{
      position: relative;
      background-color: rgba(0,0,0,.8);
      padding: 1.6rem;
      margin-top: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond(tabPort){
        margin-top: 6rem;
      }
      &-img{
        width: 45%;
        @include respond(tabPort){
          width: 24%;
        }
      }
      @include respond(tabPort){
        flex-direction: column;
      }
      &:after{
        width: 100%;
        height: .8rem;
        background-color: #11DF70;
        content: "";
        position: absolute;
        top: -1.5rem;
        left: 0;
        right: 0;
      }
    }

    .datebox{
      color:  rgba(197, 238, 216, 0.97);
      display: inline-flex;
      background-color: #1A7041;
      align-items: center;
      padding: 1rem;
      position: relative;
      @include respond(phone){
        display: none;
      }
      &:after{
        height: 100%;
        width: .8rem;
        background-color: #000000;
        content: "";
        position: absolute;
        right: -1.5rem;
      }

      &Month{
        font-size:1.8rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      &Day{
        font-size: 7.2rem;
        font-weight: 700;

      }
      &Year{
        margin-top: 1rem;
        font-size: 2.8rem;
        font-weight: bold;
      }
    }
    .nav{
      display: flex;
      list-style: none;
      background-color: rgba(0,0,0,.6);
      padding: 1.5rem;
      transition: all .35s ease-in;
      @include respond(phone){
        display: inline-flex;
        flex-wrap: wrap;
        justify-content:flex-end;
      }
      @include respond(tabPort){
        display: none;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        flex-direction: column;
        background-color: rgba(0,0,0,.95);
        z-index: 100;
        align-items: center;
        justify-content: center;
      }
      &-active{
        color: #11DF70;
      }
      li{
        font-size: 1.8rem;
        padding: 0 1.8rem;
        a{
          text-decoration: none;
          color: white;
          &:hover{
            color: #37A050;
          }
        }

      }
    }

  }
}
.logo{
  height: 6rem;
}

.anime1{
  animation: slide 15s reverse linear infinite;
}

.anime2{
  animation: slide 18s linear  infinite;
  animation-delay: .2s;
}

@keyframes slide {

  50% {
    transform: translate3d(0, -620px, 0); /* The image width */
  }
}


@keyframes zoom {
  100%{
    transform: scale(.97);
  }
}

.nextBtn{
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 200;

  //transform: translate(-50%,-50%);
  animation: upAndDown .7s alternate infinite;
  margin: 0 auto;
  @include respond(tabPort){
    bottom: 6rem;
    right: 1rem;
  }
}
.custom_grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 2.5rem;
  @include respond(tabPort){
    grid-template-columns: 1fr 1fr;
  }
  //@include respond(phone){
  //  grid-template-columns: 1fr;
  //}
}
@keyframes upAndDown {
  100%{
    transform: translateY(2%) scale(1.1);
  }
}