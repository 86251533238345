.grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  @include respond(tabPort) {
    flex-direction: column;
    align-items: center;
    gap: 3rem 0;
  }
  &-item-f {
    flex: 1;
  }
  &-item1_4 {
    flex: 0.25;
    @include respond(tabLand) {
      flex: 0.5;
      width: 100%;
    }
    @include respond(tabPort) {
      flex: 1;
      width: 100%;
    }
  }
  &-item2_4 {
    flex: 0.5;
    @include respond(tabLand) {
      flex: 0.5;
      width: 100%;
    }
    @include respond(tabPort) {
      flex: 1;
      width: 100%;
    }
  }
  &-item3_4 {
    flex: 0.75;
    @include respond(tabLand) {
      flex: 0.5;
      width: 100%;
    }
    @include respond(tabPort) {
      flex: 1;
      width: 100%;

    }
  }
  &-item1_3 {
    flex: 0.33;

    @include respond(tabLand) {
      flex: 0.5;
      width: 100%;
    }
    @include respond(tabPort) {
      flex: 1;
      width: 100%;

    }
  }
  &-item2_3 {
    flex: 0.62;

    @include respond(tabLand) {
      flex: 0.5;
      width: 100%;
    }
    @include respond(tabPort) {
      flex: 1;
      width: 100%;

    }
  }
}

.phone-row-rev{
  @include respond(tabPort){
    flex-direction: column-reverse;
  }
}

.grid-{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  @include respond(tabPort) {
    flex-direction: column;
    gap: 3rem 0;
  }
}