._mgT8{
  @include respond(tabPort){
    margin-top: 8rem;
  }
}
.overflowHidden{
  overflow-x: hidden;
}
.center-all{
   width: 80%;
  margin: 2rem auto;
  @include respond(tabPort){
    width: 95%;
  }
}
.Gcenter-all{
   width: 80%;
  margin: 0 auto;
  @include respond(tabPort){
    width: 95%;
  }
}
.em{
  font-weight: bold;
}
._greyCard{
  background-color: #181b1f;
  padding: 1.5rem;
  display: inline-block;
}

.textCenter{
  text-align: center;
}
.greenText{
  color: var(--primary);
}
.mgT1{
  margin-top: 1rem;
}
.mgT8{
  margin-top: 8rem;
}
.mgT12{
  margin-top: 12rem;
}
.mgT4{
  margin-top: 4rem;
}
.mgT2{
  margin-top: 2rem;
}
.pdT8{
  padding-top: 8rem;
}

.pdV8{

  padding: 8rem 0;
}
.width80{
  width: 80%;
}
.mgL2{
  margin-left: 2rem;
}
.mgL1{
  margin-left: 1rem;
}
.mgL2max{
  margin-left: 2rem;
  @include respond(tabPort){
    margin-left: 0;
  }
}

.circular_rad{
  border-radius: 200rem;
  border: 4px solid var(--primary-dark);
  filter: grayscale(150%);

  &:hover,&:active{
    filter: grayscale(0);
  }
}
.fluidImg{
  width: 100%;
  transition: all .35s ease-in;
  //filter: grayscale(200%);;
  &:hover{
    //filter: grayscale(0%);;
    transform: translateY(-.5rem);
  }
}
.fluidImgBod{
  width: 100%;
  padding: 1.5rem;
  border: 4px solid var(--primary);
}
.img_box{
  padding: 2rem;
  background-color: var(--dark);

}
.vid_box{
  height: auto;
  background-color: var(--dark);

}
.edgeSqre{
  position: absolute;
  z-index: 3;
  bottom: -4rem;
  right: -4rem;
}
.shadowText{
  text-shadow: 0 4px 16px rgba(0,0,0,.25);
}
.border-card{
  padding: 1.5rem;
  border: 2px solid var(--dark);
  margin-right: 2rem;
}

.greyBox{
  background-color: #EAEAEA ;
  padding: 4rem;
  position: relative;
  min-height: 80vh;
  &:before{
    position: absolute;
    width: 10000px;
    transform: translate(-50%,-50%);
    height: 100vh;
    z-index: -1;

    background-color: #f7f7f7 ;
    content: "";
  }
  &:after{
    position: absolute;
    width: 10000px;
    transform: translate(-50%,0);
    height: 50vh;
    z-index: -1;
    bottom: 0;
    background-color: var(--dark-alt2);
    content: "";
  }
  @include respond(tabPort){
    padding: 2rem;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.mgB2{
  margin-bottom: 2rem;
}
.mgV2{
  margin: 2rem 0;
}
.mgB4{
  margin-bottom: 4rem;
}

.width60{
  width: 60%;
  @include respond(tabPort){
    width: 100%;
  }
}.width100{
  width: 100vw;
  @include respond(tabPort){
    width: 100%;
  }
}

.width45{
  width: 45%;
  @include respond(tabPort){
    width: 100%;
  }
}
.width15{
  width: 15%;
  @include respond(tabPort){
    width: 100%;
  }
}

.pg{
  margin: 1rem;
  padding: 1rem;
  background-color: var(--dark);
  color: white;
  &:hover{
    color: var(--prim);
  }

 &-active{
   color: var(--prim);
 }
}

.greyCard{
  padding: 2rem;
  background-color: var(--dark);
  color: var(--primary-lime);

}

.story_list{
  //padding: 1rem;
  list-style: none;
  display: inline-block;
  li{
    font-size: 1.6rem;
    &:not(:last-child){
      margin-bottom: 1rem;
    }
  }
}
.story__list{
  //padding: 1rem;
  list-style: none;
  display: inline-block;
  li{
    font-size: 1.6rem;
    padding: 1.25rem;
    background-color: black;
    color:#f7f7f7;
    font-weight: bold;
    display: inline-block;
    &:not(:last-child){
      margin-right: 1rem;
    }
    transition: all .35s ease-out;
    @include respond(tabPort){
      margin: 1rem;
    }
    &:hover{
      transform: translateY(-5%);
    }
  }
}

.display_flex{
  display: flex;

}
.just_btw{
  justify-content: space-between;
}
.just_end{
  justify-content: flex-end;
}
.align_center{
  align-items: center;
}
.social_icon{
  &:not(:first-child){
    margin-left: 1.5rem;
  }
}


.whiteCard{
  padding: 2rem;
  background-color: white;
}